const hamburguer = document.querySelector('.hamburguer'),
  navMenu = document.querySelector('.nav-menu'),
  navLinks = document.querySelectorAll('.nav-link');

document.addEventListener('DOMContentLoaded', function () {
  inicarApp();
});

function inicarApp() {
  // aparecerElementos();
  hamburguerMenu();
  // esconderHeader();
}

function hamburguerMenu() {
  // Toggle menu lateral
  hamburguer.addEventListener('click', function () {
    hamburguer.classList.toggle('active');
    navMenu.classList.toggle('active');
  });

  // Iterar por click de links para cerrar menu
  navLinks.forEach(function (navLink) {
    navLink.addEventListener('click', function () {
      hamburguer.classList.remove('active');
      navMenu.classList.remove('active');
    });
  });
}
